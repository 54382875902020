<template>
  <div>
    <el-page-header @back="goBack" content="文章修改页面"></el-page-header>
    <div class="new">
      <el-form label-width="80px">
        <el-form-item label="封面图">
          <div class="button-block">
            <img width="170" class="diss" height="170" v-if="imgvis" :src="api+img" />
            <!-- <img :src="img" width="300px" />
            <el-button style="position:relative" type="primary" @click="selectImg">
              选择图片
              <label for="selImg"></label>
            </el-button>
            <input type="file" id="selImg" ref="img" @change="selectImg" />-->
            <el-upload
              :action="surl"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .JPG, .JPEG, .PBG"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="PICsuccess"
              :limit="1"
              class="Imege diss"
              :file-list="fileList1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- img-src -->
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="img" alt />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="文章标题">
          <el-input v-model="title" class="text"></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
          <div class="editor">
            <!-- :isClear="isClear" -->
            <editor-bar v-model="content" class="font-siz"></editor-bar>
          </div>
          <!-- <el-input v-model="content" class="text" type="textarea" rows="5"></el-input> -->
        </el-form-item>
        <el-form-item label="文章导语">
          <el-input v-model="intro" class="text"></el-input>
        </el-form-item>
        <el-form-item label="是否推荐">
          <el-button
            v-if="isRecommend == 1"
            type="text"
            size="small"
            @click="recommend(isRecommend)"
          >是</el-button>
          <el-button
            v-if="isRecommend == 0"
            type="text"
            size="small"
            @click="recommend(isRecommend)"
          >否</el-button>
        </el-form-item>
        <el-form-item label="是否精选">
          <el-button
            v-if="isChoiceness == 1"
            type="text"
            size="small"
            @click="selected(isChoiceness)"
          >是</el-button>
          <el-button
            v-if="isChoiceness == 0"
            type="text"
            size="small"
            @click="selected(isChoiceness)"
          >否</el-button>
        </el-form-item>
        <el-form-item label="引用">
          <el-input v-model="reference" class="text"></el-input>
        </el-form-item>
        <el-form-item label="创作时间">
          <el-input :disabled="true" v-model="createTime" class="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="modify()">立即修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import EditorBar from "@/components/wangEnduit/index";
import { showTimeFormat } from "@/js/common/index.js";
//加载数据库
import { QUERYED, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      api: this.api.LoginURL.concat(),
      imgvis: false,
      surl: this.api.LoginURL.concat("/resources/trading/resourcesUpload"),
      isClear: false,
      id: 0,
      img: "",
      title: "",
      content: "",
      isChoiceness: 0,
      isRecommend: 0,
      reference: "",
      intro: "",
      createTime: "",
      author: "",
      fileList1: [],
      dialogVisible: false
      //   dialogVisible: false
    };
  },
  components: { EditorBar },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    //点击文件列表中已上传的文件时的钩子（图片）
    handlePictureCardPreview(file) {
      this.img = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    }, //上传图片成功后返回的数据（图片）
    PICsuccess(file, fileList) {
      this.img = file.data.url;
      console.log("上传返回的图片路径：" + this.img);
      this.fileList1[0] = fileList;
      this.fileList1[0].url = this.api + file.data.url;
      this.imgvis = false;
    },
    //加载
    async init() {
      let data = await QUERYED(
        "post",
        "",
        "   TtArticle(where: {id:  {_eq: " +
          this.id +
          "}}) { id img intro title  content author  coverImg isRecommend reference createTime isChoiceness }"
      );
      this.author = data.data.TtArticle[0].author;
      this.id = data.data.TtArticle[0].id;
      this.img = data.data.TtArticle[0].coverImg;
      this.title = data.data.TtArticle[0].title;
      this.content =
        data.data.TtArticle[0].coverImg != null &&
        data.data.TtArticle[0].coverImg.substring(0, 7) == "http://"
          ? data.data.TtArticle[0].content
          : data.data.TtArticle[0].content.replace(/[\'\"\\\\]/g, "");
      this.isChoiceness = data.data.TtArticle[0].isChoiceness;
      this.isRecommend = data.data.TtArticle[0].isRecommend;
      this.reference = data.data.TtArticle[0].reference;
      this.intro = data.data.TtArticle[0].intro;
      this.createTime = showTimeFormat(data.data.TtArticle[0].createTime);
      if (this.img) {
        this.imgvis = true;
      }
    },
    ////修改文章
    async modify() {
      let data = await UPDATE(
        "POST",
        "",
        'update_TtArticle(_set: {coverImg: "' +
          this.img +
          '", title: "' +
          this.title +
          '",isChoiceness:' +
          this.isChoiceness +
          ",isRecommend:" +
          this.isRecommend +
          ',reference:"' +
          this.reference +
          '",intro: "' +
          this.intro +
          '",createTime: "' +
          new Date() +
          '"content: ' +
          JSON.stringify(JSON.stringify(this.content)) +
          "}, where: {id: {_eq:" +
          this.id +
          "}}) {  affected_rows }"
      );
      if (data.data.update_TtArticle.affected_rows > 0) {
        if (this.message("修改", this.title, this.author, 0, this.id)) {
          this.$message({
            message: "修改成功!",
            type: "success"
          });
          this.$router.push("/selected");
        }
      } else {
        //alert("修改失败！");
        this.$message({
          message: "修改失败！",
          type: "warning"
        });
      }
    },
    //发消息通知
    async message(state, title, authorid, type, articleid) {
      let XT = await INSERT(
        "post",
        "",
        ' insert_XTMessage(objects: {state :"' +
          state +
          '",title :"' +
          title +
          '",userid  :' +
          authorid +
          " ,type :" +
          type +
          " ,articleid :" +
          articleid +
          ',creatime :"' +
          new Date() +
          '"}) { affected_rows  }'
      );
      if (XT.data.insert_XTMessage.affected_rows == 1) {
        return true;
      } else {
        return false;
      }
    },
    //是否推荐
    async recommend(isRecommend) {
      //alert(isRecommend)
      if (isRecommend == 0) {
        this.isRecommend = 1;
      } else {
        this.isRecommend = 0;
      }
    },
    //是否精选
    async selected(isChoiceness) {
      if (isChoiceness == 0) {
        this.isChoiceness = 1;
      } else {
        this.isChoiceness = 0;
      }
    },
    selectImg(e) {
      let inputDOM = this.$refs.img;
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        this.img = URL.createObjectURL(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: "格式不正确,请上传图片文件",
          type: "warning"
        });
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.img = file.url;
      // this.dialogVisible = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    realTime(data) {
      this.previews = data;
    }
  }
};
</script>
<style scoped>
.new {
  margin-top: 20px;
}
.text {
  width: 500px;
}
.button-block {
  /* height: 180px; */
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#selImg {
  display: none;
}
.editor {
  width: 800px;
  height: 350px;
  /* border: 1px solid red; */
}
::v-deep .editor .w-e-text-container {
  display: inline-block;
  width: 800px !important;
  height: 300px !important;
}
.Imege {
  width: 350px;
  /* position: absolute;
  top: 20px;
  left: 170px; */
}
.diss {
  display: inline-block;
  vertical-align: top;
}
</style>
